import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const RegularizationFilter = ({ tableData = [], setFilteredData }) => {
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setFilteredData(filteredAndSortedData());
  }, [tableData]);

  const resetFilter = () => {
    setSearchText("");
    setSortOption("default");
    setStartDate(null);
    setEndDate(null);
    setFilteredData(tableData);
  };

  const filteredAndSortedData = () => {
    return tableData
      .filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          item?.phoneNumber?.toLowerCase()?.includes(searchText?.toLowerCase())
        );
      })
      .sort((a, b) => {
        if (sortOption === "name") {
          return a?.name?.localeCompare(b.name);
        } else if (sortOption === "phoneNumber") {
          return a?.phoneNumber?.localeCompare(b.phoneNumber);
        }
        return 0;
      })
      .filter((item) => {
        const date =  item?.createdDate.toDate();
        return (
          (!startDate || date >= startDate) &&
          (!endDate || date <= addDay(endDate))
        );
      });
  };

  const handleFilter = () => setFilteredData(filteredAndSortedData());

  return (
    <FilterContainer>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <FormControl style={{ width: "20%" }}>
        <InputLabel id="Sorting">Sort By</InputLabel>
        <Select
          id="sort"
          label="Sort By"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <MenuItem value="default">All</MenuItem>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="phoneNumber">Phone</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate ? new Date(startDate) : startDate}
          onChange={(date) => setStartDate(date?.$d)}
        />

        <DatePicker
          label="End Date"
          value={endDate ? new Date(endDate) : endDate}
          onChange={(date) => setEndDate(date?.$d)}
        />
      </LocalizationProvider>
      <Button variant="contained" color="secondary" onClick={resetFilter}>
        Reset
      </Button>
      <Button variant="contained" onClick={handleFilter}>
        Filter
      </Button>
    </FilterContainer>
  );
};

export default RegularizationFilter;

const FilterContainer = styled.div`
  display: flex;
  gap: 20px;
`;

function addDay(inputDate) {
  let date = new Date(inputDate);
  date.setDate(date.getDate() + 1);
  return date;
}