import React, { useState, useEffect } from "react";
import styled from "styled-components";
import db from "../../firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import RegularizationFilter from "./RegularizationFilter";
import RegularizationTable from "./RegularizationTable";

const Regularization = () => {
  const [regularizationData, setRegularizationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getRegularizationData = async () => {
    const formRef = query(
      collection(db, "Regularization"),
      orderBy("createdDate", "desc")
    );

    try {
      onSnapshot(formRef, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRegularizationData(data);
        setFilteredData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    getRegularizationData();
  }, []);

  return (
    <>
      <SitePage>
        <TitleContainer>
          <h2>Regularization</h2>
          <p>Overview of regularization requests</p>
        </TitleContainer>
        <RegularizationFilter
          tableData={regularizationData}
          setFilteredData={setFilteredData}
        />
        <RegularizationTable filteredData={filteredData} />
      </SitePage>
      
    </>
  );
};



const SitePage = styled.div`
  padding: 30px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export default Regularization;
