import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import styled from "styled-components";
  
  const SalaryFilter = ({ tableData = [], setFilteredData }) => {
    const [searchText, setSearchText] = useState("");
    const [sortOption, setSortOption] = useState("default");
  
    useEffect(() => {
      setFilteredData(filteredAndSortedData());
    }, [tableData]);
  
    const resetFilter = () => {
      setSearchText("");
      setSortOption("default");
      setFilteredData(tableData);
    };
  
    const filteredAndSortedData = () => {
      return tableData
        .filter((item) => {
          return (
            item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
            item?.phoneNumber?.toLowerCase()?.includes(searchText?.toLowerCase())
          );
        })
        .sort((a, b) => {
          if (sortOption === "name") {
            return a?.name?.localeCompare(b.name);
          } else if (sortOption === "phoneNumber") {
            return a?.phoneNumber?.localeCompare(b.phoneNumber);
          }
          return 0;
        })
    };
  
    const handleFilter = () => setFilteredData(filteredAndSortedData());
  
    return (
      <FilterContainer>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <FormControl style={{ width: "20%" }}>
          <InputLabel id="Sorting">Sort By</InputLabel>
          <Select
            id="sort"
            label="Sort By"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <MenuItem value="default">All</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="phoneNumber">Phone</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="secondary" onClick={resetFilter}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleFilter}>
          Filter
        </Button>
      </FilterContainer>
    );
  };
  
  export default SalaryFilter;
  
  const FilterContainer = styled.div`
    display: flex;
    gap: 20px;
  `;