import React,{useState} from "react";
import styled from "styled-components";
import { Button, TablePagination } from "@mui/material";
import db from "../../firebase";
import { doc, updateDoc} from "firebase/firestore";
import {addRegularizationData} from "../../Backend/addDataToFirebase";
import {convertDateTimeToTimestamp, convertDateToISOFormat, sendNotification} from "../../Backend/helperFunctions";
import CustomPopup from "../customPopup";
import {getUserForPhoneNumber} from "../../Backend/getDataFromFirebase";


const RegularizationTable = ({ filteredData }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [confirmParams, setConfirmParams] = useState(null);
  let status = "Pending";

  const handleOpen = (e, id, item) => {
    setConfirmParams({ e, id, item });
    setPopupOpen(true);
  };


  const handleConfirm = () => {
    if (confirmParams) {
      const { e, id, item } = confirmParams;
      handleAction(e, id, item);
      setPopupOpen(false);
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleAction = async (e, id, item) => {
    status = e.target.innerText === "APPROVE" ? "Approved" : "Rejected";
    const currentAttendanceData = createAttendanceData(item);

    let title = "Update on Regularization Request";
    let body = `Your regularization request has been ${status.toUpperCase()}.`;
    let expoToken = [];

    try {
      const docRef = doc(db, "Regularization", id);
      await updateDoc(docRef, { status: status }); 
      console.log(`Document ${id} updated to ${status}`);

      const result = await getUserForPhoneNumber(item?.phoneNumber);
      if (result.status) {
        expoToken = result?.data[0].expoToken;
      }

      if (status === "Approved") {
        if (item?.attendenceDataId && item?.attendenceDataId !== "Not Available") {
          const attendanceDocRef = doc(db, "UserAttendance", item?.attendenceDataId);
          await updateDoc(attendanceDocRef,  {
            checkInTime: convertDateTimeToTimestamp(item?.checkInTime, item?.requestedDate),
            checkOutTime: convertDateTimeToTimestamp(item?.checkOutTime, item?.requestedDate),
          });
          console.log(`Attendance document ${item?.attendenceDataId} updated.`);
        } else {
          await addRegularizationData(currentAttendanceData);
          console.log(`New attendance document created.`);
        }
      }
      sendNotification(expoToken, title, body);
    } catch (error) {
      console.error("Error updating or creating document:", error);
    }
  };

  const createAttendanceData = (item) => ({
    checkInImage: "",
    checkInLocation: {},
    checkInTime: convertDateTimeToTimestamp(item?.checkInTime,item?.requestedDate),
    checkInSiteName: "Regularized",
    checkOutImage: "",
    checkOutLocation: {},
    checkOutTime: convertDateTimeToTimestamp(item?.checkOutTime,item?.requestedDate),
    date: convertDateToISOFormat(item?.requestedDate),
    name: item?.name,
    phoneNumber: item?.phoneNumber,
    userID : item?.userId,
  });


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <CustomPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message="Are you sure you want to proceed?"
        confirmLabel="Yes"
        cancelLabel="No"
      />
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Requested Date</th>
              <th>CheckIn Time</th>
              <th>Requested CheckIn</th>
              <th>CheckOut Time</th>
              <th>Requested CheckOut</th>
              <th>Reson</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {filteredData  
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) =>{
                status = item?.status;
                if(item?.attendenceDataId !== "Not Available"){

                }                
                return(
                  <tr key={index.toString()}>
                      <td>{item?.name}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.requestedDate}</td>
                      <td>Absent</td>
                      <td>{item?.checkInTime}</td>
                      <td>Absent</td>
                      <td>{item?.checkOutTime}</td>
                      <td>{item?.reason}</td>
                      <td>{status}</td>
                      
                      <td>
                      {["Approve", "Reject"].map((action) => (
                        <Button
                          key={action}
                          className={action === "Approve" ? "actionBtnApproval" : "actionBtnRejection"}
                          disabled={status !== "Pending"}
                          onClick={(e) => {
                            handleOpen(e, item?.id, item);
                          }}
                        >
                          {action}
                        </Button>
                      ))}
                      </td>
                  </tr>
              );
          })}
          </tbody>
        </table>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default RegularizationTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .centerr {
    display: flex;
    justify-content: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
  button {
    width: 100px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
  }
    .actionBtnApproval {
    background-color: #4caf50;
    color: #fff;
        &:hover {
      background-color: #45a049;
    }
  }
  .actionBtnRejection {
    background-color: #f44336;
    color: #fff;
    &:hover {
      background-color: #d32f2f; 
    }
  }
`;
