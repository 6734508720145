import { Timestamp } from "firebase/firestore";

export const convertDateTimeToTimestamp = (time, date) => {
  if (!time || !date) {
    console.error("Invalid time or date:", { time, date });
    return null; // Return null or a default value
  }

  // Split the input date string to extract date components
  const [dayOfWeek, monthDayYear] = date.split(",");  // Example: "Tue, Jan 7 2024"
  const [month, day, year] = monthDayYear.trim().split(" ");  // Example: ["Jan", "7", "2024"]

  const [hoursMinutes, ampm] = time.split(/\s+/);  // Example: "9:07:50", "AM"
  const [hour, minute, second] = hoursMinutes.split(":");  // Example: ["9", "07", "50"]

  const utcOffset = "UTC+5:30";

  const formattedString = `${month} ${day}, ${year} at ${hour}:${minute}:00${ampm} ${utcOffset}`;

  const jsDate = new Date(`${month} ${day}, ${year} ${hour}:${minute}:00 ${ampm}`);
  return Timestamp.fromDate(jsDate);
};


export const convertDateToISOFormat = (date) => {
  if (!date) {
    console.error("Invalid date:", date);
    return null; // Return null or a default value
  }

  // Split the date to extract the components (month, day, year)
  const [dayOfWeek, monthDayYear] = date.split(",");  // "Tue, Jan 7 2025"
  const [month, day, year] = monthDayYear.trim().split(" ");  // "Jan", "7", "2025"

  // Define month mappings for abbreviated months
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  // Validate month and format it correctly
  const formattedMonth = months[month];
  if (!formattedMonth) {
    console.error("Invalid month:", month);
    return null;
  }

  // Format day to ensure it's always 2 digits
  const formattedDay = day.length === 1 ? `0${day}` : day;

  // Combine into YYYY-MM-DD format
  const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
  return formattedDate;
};

//Helper function for getting attendance status
export const parseTimeToMinutes = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let totalMinutes = hours * 60 + (minutes || 0);
  if (modifier === "PM" && hours !== 12) totalMinutes += 720; // Add 12 hours for PM, except for 12 PM
  if (modifier === "AM" && hours === 12) totalMinutes -= 720; // Subtract 12 hours for 12 AM

  return totalMinutes;
};

//Fetch the status (Present/ Absent/ Half Day)
export const determineAttendanceStatus = (item, dataUser, holidayData) => {

const itemDate = new Date(item.date).toLocaleDateString("en-CA");

if (!item?.checkInTime || !item?.checkOutTime) {
  const isHoliday = holidayData.some((holiday) => holiday.date === itemDate);

  return isHoliday ? "Holiday" : "Absent";
}

  const checkInMinutes = parseTimeToMinutes(item.checkInTime);
  const checkOutMinutes = parseTimeToMinutes(item.checkOutTime);

  const defaultCheckIn = 600; // 10:00 AM in minutes
  const defaultCheckOut = 1140; // 07:00 PM in minutes

  const userCheckIn = dataUser?.checkIn
    ? parseInt(dataUser.checkIn.split(":")[0]) * 60 + parseInt(dataUser.checkIn.split(":")[1])
    : defaultCheckIn;

  const userCheckOut = dataUser?.checkOut
    ? parseInt(dataUser.checkOut.split(":")[0]) * 60 + parseInt(dataUser.checkOut.split(":")[1]) + 720 // Convert PM
    : defaultCheckOut;

  if (checkInMinutes <= userCheckIn && checkOutMinutes >= userCheckOut) {
    return "Present";
  } else {
    return "Half Day";
  }
};

export const calculateSalary = (attendanceStatuses, basicSalary, holidays) => {

    if(basicSalary == undefined){
      basicSalary = 0;
    }
    const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    const presentDays = attendanceStatuses.filter((status) => status === "Present").length;
    const halfDays = attendanceStatuses.filter((status) => status === "Half Day").length;

    const paymentPerDay = daysInMonth > 0 ? Number(basicSalary) / daysInMonth : 0;
    const fullDayEquivalent = presentDays + halfDays * 0.5;

    const totalSalary = paymentPerDay * (fullDayEquivalent + holidays);

    return totalSalary.toFixed(2); // Return total salary rounded to 2 decimal places
  };

  export const generateIndexes = (data) => {
    if (!data.length) return [];

    const dateOfJoining = new Date(Math.min(...data.map((d) => d.timestamp.toDate())));
    const currentDate = new Date();
    const tempDate = new Date(dateOfJoining);

    const indexArray = [];
    currentDate.setDate(1);

    while (tempDate <= currentDate) {
      const month = tempDate.toLocaleString("default", { month: "long" });
      const year = tempDate.getFullYear();
      const value = `${String(tempDate.getMonth() + 1).padStart(2, "0")}-${year}`;

      indexArray.push({ label: `${month}, ${year}`, value });
      tempDate.setMonth(tempDate.getMonth() + 1);
    }

    return indexArray;
  };

  export const sendNotification = async (pushTokens, title, body) => {
    try {
      for(const token of pushTokens)  {
        const messages = {
          to: token,
          sound: "default",
          title: title,
          body: body,
          data: { additionalData: "Extra info here" },
        };
    
        const response = await fetch("https://exp.host/--/api/v2/push/send", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messages),
          mode: "no-cors"
        });
    
        console.log("Notification response:", response);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };  