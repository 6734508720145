import React from "react";
import styled from "styled-components";
import { TablePagination } from "@mui/material";

const formatDate = (date) => {
  if (!date) return [];
  let updatedDate = new Date(date?.seconds * 1000);
  const formattedDate = updatedDate.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
    day: "numeric",
  });

  const formattedTime = updatedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateArray = formattedDate.split("/");
  return [dateArray[0], formattedTime];
};

const AttendanceTable = ({ filteredData, handlerImg }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Date</th>
              <th>CheckIn Site</th>
              <th>CheckIn Time</th>
              <th>CheckOut Time</th>
              <th>CheckIn Selfie</th>
              <th>CheckOut Selfie</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                const [, checkInTime = ""] = formatDate(item?.checkInTime);
                const [, checkOutTime = ""] = formatDate(item?.checkOutTime);
                return (
                  <tr key={index.toString()}>
                    <td>{item?.name}</td>
                    <td>{item?.phoneNumber}</td>
                    <td>{item?.date}</td>
                    <td>{item?.checkInSiteName}</td>
                    <td>{checkInTime}</td>
                    <td>{checkOutTime}</td>
                    <td>
                      <div className="centerr">
                        {item?.checkInImage ? (
                          <BgImg onClick={() => handlerImg(item.checkInImage)}>
                            <img src={item?.checkInImage} alt="img1" />
                          </BgImg>
                        ) : (
                          "No Selfie"
                        )}
                      </div>
                    </td>
                    <t>
                      <div className="centerr">
                        {item?.checkOutImage ? (
                          <BgImg onClick={() => handlerImg(item.checkOutImage)}>
                            <img src={item?.checkOutImage} alt="img1" />
                          </BgImg>
                        ) : (
                          "No Selfie"
                        )}
                      </div>
                    </t>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default AttendanceTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .centerr {
    display: flex;
    justify-content: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    color: #909090;
    svg {
      font-size: 18px;
      /* color: #909090; */
    }
  }
`;

const BgImg = styled.div`
  cursor: pointer;
  position: relative;
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
`;
