import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SalaryFilter from "./SalaryFilter";
import SalaryTable from "./SalaryTable";
import { getHolidayData, getUserAttendanceByMonth, getAllUsers } from "../../Backend/getDataFromFirebase";
import {determineAttendanceStatus, calculateSalary} from "../../Backend/helperFunctions";


const Salary = () => {
  const [salaryData, setSalaryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getSalaryData = async () => {
    try {
      const usersResponse = await getAllUsers(); // Fetch all users
      if (!usersResponse.status) {
        throw new Error("Failed to fetch users");
      }
      const users = usersResponse.data;

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      month: month.toString().padStart(2, "0")
  
      const holidayResponse = await getHolidayData(month,year); // Fetch holiday data
      if (!holidayResponse.hstatus) {
        throw new Error("Failed to fetch holidays");
      }
      const holidays = holidayResponse.hdata || [];
  
      const salaryGetData = await Promise.all(
        users.map(async (user) => {
          if (!user.phoneNumber) {
            console.warn(`Skipping user ${user.name} due to missing phone number.`);
            return null; // Skip users without phoneNumber
          }
  
          const attendanceResponse = await getUserAttendanceByMonth(user.phoneNumber,month, year);
          if (!attendanceResponse.status) {
            console.warn(`Failed to fetch attendance for ${user.phoneNumber}`);
            return null; // Skip if attendance fetching fails
          }
  
          const attendanceStatuses = attendanceResponse.data.map((day) =>
            determineAttendanceStatus(day, user, holidays)
          );
  
          const salary = calculateSalary(attendanceStatuses, user.salary, holidays.length);
  
          return {
            name: user.name,
            phoneNumber: user.phoneNumber,
            salary,
            presentDays: attendanceStatuses.filter((status) => status === "Present").length,
            halfDays: attendanceStatuses.filter((status) => status === "Half Day").length,
            holidays: holidays.length,
          };
        })
      );
  
      // Filter out null values (users with missing phone numbers or failed attendance fetch)
      const validSalaryData = salaryGetData.filter((data) => data !== null);
  
      setSalaryData(validSalaryData);
      setFilteredData(validSalaryData);
    } catch (error) {
      console.error("Error fetching salary data:", error);
    }
  };
  

  useEffect(() => {
    getSalaryData(); // Fetch salary data on component mount
  }, []);

  return (
    <>
      <SitePage>
        <TitleContainer>
          <h2>Salary</h2>
          <p>Overview of salary data</p>
        </TitleContainer>
        <SalaryFilter
          tableData={salaryData}
          setFilteredData={setFilteredData}
        />
        <SalaryTable filteredData={filteredData} />
      </SitePage>
    </>
  );
};

const SitePage = styled.div`
  padding: 30px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export default Salary;