import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 20px 0px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.h3`
  margin-bottom: 12px;
`;

const AddDocuments = ({ documents = [], setDocuments }) => {
  const handleAddField = () => {
    setDocuments([...documents, { name: "", number: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedDocuments = documents.filter((_, i) => i !== index);
    setDocuments(updatedDocuments);
  };

  const handleChange = (index, field, value) => {
    const updatedDocuments = documents.map((doc, i) =>
      i === index ? { ...doc, [field]: value } : doc
    );
    setDocuments(updatedDocuments);
  };

  const handleFileUpload = (index, file) => {
    const updatedDocuments = documents.map((doc, i) =>
      i === index ? { ...doc, file } : doc
    );
    setDocuments(updatedDocuments);
  };

  return (
    <div>
      <Wrapper>
        <Heading>Documents</Heading>
        <Button
          variant="outlined"
          onClick={handleAddField}
          startIcon={<AddIcon />}
        >
          Add Document
        </Button>
      </Wrapper>
      {documents.map((doc, index) => (
        <RowContainer>
          <Row key={index}>
            <Select
              fullWidth
              value={doc.name}
              onChange={(e) => handleChange(index, "name", e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Select Document</MenuItem>
              {documentOptions?.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>

            <TextField
              fullWidth
              label="Document Number"
              value={doc.number}
              onChange={(e) => handleChange(index, "number", e.target.value)}
            />
            <IconButton onClick={() => handleRemoveField(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Row>
          <div>
            {typeof doc?.file === "string" ? (
              <p>
                Uploaded File:{" "}
                <strong>
                  {typeof doc?.file === "string" ? (
                    <Link to={doc?.file} target="_blank" rel="noopener noreferrer">Link</Link>
                  ) : null}
                </strong>
              </p>
            ) : (
              <>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleFileUpload(index, e.target.files[0])}
                />
                {doc?.file && (
                  <p>
                    Uploaded File: <strong>{doc?.file?.name}</strong>
                  </p>
                )}
              </>
            )}
          </div>
        </RowContainer>
      ))}
    </div>
  );
};

export default AddDocuments;

const documentOptions = [
  "Aadhaar Card",
  "PAN Card",
  "Passport",
  "Driving License",
  "ESIC Number",
  "PF No",
  "UAN No",
  "Date of Joining",
  "Bank Name",
];
