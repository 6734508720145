import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Details from "../components/Details";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "./Modal";
import { SetModal } from "../features/modalSlice";
import Table from "../ui/Table/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import db from "../firebase";
import { selectType } from "../features/modalSlice";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Addholidays from "./AddHolidays";

const Holidays = () => {
  const modalType = useSelector(selectType);
  const [holidays, setHolidays] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState("");
  const [search, setSearch] = useState("");
  const [details, setDetails] = useState("");
  const [editForm, setEdit] = useState("");
  const [formId, setFormId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (search) {
      const res = holidays.filter((item) =>
        item.name?.toLowerCase().includes(search.toLowerCase())
      );
      setData(res);
    } else {
      setData(holidays);
    }
  }, [search, holidays]);

  
  useEffect(() => {
    (async () => {
      try {
        const userRef = query(
          collection(db, "Holidays"),
          orderBy("timestamp", "desc")
        );
        onSnapshot(userRef, (querySnapshot) => {
          setHolidays(
            querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
          setData(
            querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    const handlerESC = (event) => {
      var code = event.code;
      if (code === "Escape") {
        setDetails("");
        setModal("");
      }
    };
    document.addEventListener("keydown", handlerESC);

    return () => {
      document.removeEventListener("keydown", handlerESC);
    };
  }, []);

  const handlerDetails = (id) => {
    switch (modal) {
      case "Holidays":
        setDetails("");
        setModal("");
        break;
      case "":
        setDetails(id);
        setModal("Holidays");
        break;
      default:
        setModal("");
    }
  };

  const shoudDisable = (id) => {
    const today = new Date().toISOString().split('T')[0];
    const dbDate = holidays.find((h) => h.id === id)?.date;
    return dbDate < today;
  }

  const handlerDelete = async (id) => {
    dispatch(
      SetModal({
        type: "Supervisor",
        dbName: "Holidays",
        id: id,
      })
    );
  };

  const handleEdit = (formId) => {
    switch (editForm) {
      case "addholidays":
        setEdit("");
        setFormId("");
        break;
      case "":
        setFormId(formId);
        setEdit("addholidays");
        break;
      default:
        setEdit("");
        setFormId("");
    }
  };

  const columns = [
    { id: "name", label: "Name" },
    { id: "date", label: "Date" },
    { id: "applicableFor", label: "Applicable For" },
    { id: "holidayType", label: "Holiday Type" },
    {
      id: "id",
      label: "Action",
      Cell: (id) => (
        <ButtonWrapper>
          <button onClick={() => handlerDetails(id)}>
            <VisibilityIcon />
          </button>
          <button onClick={() => handleEdit(id)}>
            <EditIcon />
          </button>
          <button
            style={{ color: "#F44336" }}
            onClick={() => handlerDelete(id)}
            disabled={shoudDisable(id)}
          >
            <DeleteOutlinedIcon />
          </button>
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <>
      <SitePage>
        <SearchContainer>
          <div>
            <h2>Holiday Data Table</h2>
            <p>Overview of all Holidays</p>
          </div>
          <div>
            <Fields>
              <label htmlFor="">Search</label>
              <input
                type="text"
                placeholder="Search by name..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Fields>
          </div>
        </SearchContainer>
        <Table data={data} columns={columns} />
      </SitePage>
      { editForm === 'addholidays' && <Addholidays editForm={editForm} handlEdit={handleEdit} formId={formId} />}
      {modal && details && (
        <Details
          details={details}
          handlerDetails={handlerDetails}
          modal={modal}
        />
      )}
      {modalType && <Modal />}
    </>
  );
};

const SitePage = styled.div`
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  overflow: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  > div {
    &:last-child {
      display: flex;
      align-items: center;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;
  margin-right: 15px;
  input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    &::placeholder {
      color: #000;
    }
  }
  input,
  select {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  label {
    position: absolute;
    background-color: #fff;
    font-size: 13px;
    left: 10px;
    top: -12px;
    padding: 3px;
    color: #666666;
  }
  select,
  option {
    padding-right: 50px;
  }
`;

const BgImg = styled.div`
  margin: 0 auto;
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export default Holidays;
