import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getHolidayData, getUserAttendanceByMonth } from "../../Backend/getDataFromFirebase";
import {determineAttendanceStatus, calculateSalary} from "../../Backend/helperFunctions";

const SalaryTable = ({ month, year, userData }) => {
  const [salaryData, setSalaryData] = useState([]);

  useEffect(() => {
    fetchSalaryData();
  }, []);

  const fetchSalaryData = async () => {
    try {
      const holidayResponse = await getHolidayData(month, year); // Fetch holiday data
      if (!holidayResponse.hstatus) {
        throw new Error("Failed to fetch holidays");
      }
      const holidays = holidayResponse.hdata || [];

      const attendanceResponse = await getUserAttendanceByMonth(
        userData[0].phoneNumber,
        month,
        year
      );
      if (!attendanceResponse.status) {
        console.warn(`Failed to fetch attendance for ${userData[0].phoneNumber}`);
        return; // Exit if attendance fetching fails
      }

      const attendanceStatuses = attendanceResponse.data.map((day) =>
        determineAttendanceStatus(day, userData[0], holidays)
      );

      const salary = calculateSalary(
        attendanceStatuses,
        userData[0].salary,
        holidays.length
      );

      const calculatedData = {
        name: userData[0].name,
        phoneNumber: userData[0].phoneNumber,
        salary,
        presentDays: attendanceStatuses.filter((status) => status === "Present")
          .length,
        halfDays: attendanceStatuses.filter((status) => status === "Half Day")
          .length,
        holidays: holidays.length,
      };

      setSalaryData([calculatedData]); 
    } catch (error) {
      console.error("Error fetching salary data:", error);
    }
  };

  return (
    <div>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Present Days</th>
              <th>Half Days</th>
              <th>Holidays</th>
              <th>Salary</th>
            </tr>
          </thead>
          <tbody>
            {salaryData.map((item, index) => (
              <tr key={index.toString()}>
                <td>{item.name}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.presentDays}</td>
                <td>{item.halfDays}</td>
                <td>{item.holidays}</td>
                <td>{item.salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
};

export default SalaryTable;

const Table = styled.div`
  margin-top: 15px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  th {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 5px 0;
    color: rgba(0, 0, 0, 1);
  }
`;
