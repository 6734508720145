import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, serverTimestamp, updateDoc, } from "firebase/firestore";
import { uploadDocuments, uploadProfile } from "./helper";
import AddDocuments from "./AddDocuments";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import Loader from "./Loader";
import MenuItem from "@mui/material/MenuItem";
import RemoveIcon from "@mui/icons-material/Remove";
import Select from "@mui/material/Select";
import db from "../firebase";
import styled from "styled-components";
import { toast } from "react-toastify";

const FormData = {
  name: "",
  date: "",
  applicableFor: "",
  holidayType: "",
};

const applicableForSites = [
  "All",
  "Kajal Construction",
];

const Addholidays = ({ submitForm, show, editForm, handlEdit, formId }) => {
  const [holidaysData, setHolidaysData] = useState(FormData);
  const [showField, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const userRef = collection(db, "Holidays");


  const handleClose = (e) => {
    e.preventDefault();
    if (show === "addholidays") {
      submitForm();
    } else {
      handlEdit();
    }
  };

  const getData = async () => {
    try {
      const docSnap = await getDoc(doc(db, "Holidays", formId));
      const dataRef = docSnap.data();
      if (dataRef) {
        setHolidaysData({
            name: dataRef?.name,
            date: dataRef?.date,
            applicableFor: dataRef?.applicableFor,
            holidayType: dataRef?.holidayType,
        });
        if(dataRef?.holidayType === "Optional"){
          setChecked(true)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [formId]);

  const handlerForm = (e) => {
    const { name, value } = e.target;
    setHolidaysData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
  
    // Update holidayType based on checkbox state
    setHolidaysData((prev) => ({
      ...prev,
      holidayType: isChecked ? "Optional" : "",
    }));
  };

  const submitDb = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (formId) {

      const docRef = doc(db, "Holidays", formId);
      const docSnap = await getDoc(docRef);
      const dataRef = docSnap.data();
      if (dataRef) {
        updateDoc(docRef, holidaysData)
          .then((res) => {
            setLoading(false);
            toast.success("Successfully Updated Holiday", {
              toastId: "success1",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          })
          .catch((err) => {
            console.log(err);
          });

        handlEdit();
        setHolidaysData(FormData);
        setShowFields(false);
      }
    } else {

      const userExist = await isUserExist();
      setLoading(false);
      if (userExist) {
        toast.warn("Holiday Already Exist", {
          toastId: "success2",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const docRef = await addDoc(userRef, {
          ...holidaysData,
          timestamp: serverTimestamp(),
        });
        setLoading(false);

        if (docRef.id) {
          toast.success("Successfully Created Holiday", {
            toastId: "success1",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        console.log("Document written with ID: ", docRef.id);
        submitForm();
        setHolidaysData(FormData);
        setShowFields(false);
      }
    }
  };

  const handlerAddFields = (e) => {
    e.preventDefault();
    setShowFields(!showField);
  };

  const isUserExist = async () => {
    // Todo: check if user already exist
    return false;
  };

  return (
    <>
      {show === "addholidays" || editForm === "addholidays" ? (
        <>
          <Container>
            {loading && <Loader />}
            <Box>
              <Header>
                <div>
                  <h2>{formId ? "Edit" : "Create New"} Holiday</h2>
                  {!formId ? <p>Create a Holiday below</p> : null}
                </div>

                <Icon onClick={handleClose}>
                  <ClearIcon />
                </Icon>
              </Header>

              <Form>
                <form onSubmit={submitDb}>

                  <Inputs>
                    <div>
                      <label htmlFor="name">Holiday name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        value={holidaysData.name}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                  </Inputs>
                  <Inputs>
                    <div>
                      <label htmlFor="date">Date*</label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        placeholder="01/01/2025"
                        value={holidaysData.date}
                        onChange={handlerForm}
                        required
                      />
                    </div>
                    </Inputs>

                  
                  <Btn onClick={handlerAddFields} showField={showField}>
                    {showField ? (
                      <>
                        <RemoveIcon />
                        HIDE ADDITIONAL DETAILS
                      </>
                    ) : (
                      <>
                        <AddIcon />
                        ADD ADDITIONAL DETAILS
                      </>
                    )}
                  </Btn>
                  {showField ? (
                    <>
                      <Inputs> 
                        <div>
                          <label htmlFor="applicableFor">Applicable For</label>
                          <FormControl variant="standard" className="selectOtp">
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={holidaysData.applicableFor ? holidaysData.applicableFor : ""}
                              onChange={handlerForm}
                              label="applicableFor"
                              name="applicableFor"
                            >
                              <MenuItem value="" disabled={true}>
                                <em>Applicacle For</em>
                              </MenuItem>
                              {applicableForSites.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </Inputs>
                      <Inputs>
                        <div>
                          <label htmlFor="holidayType">Holiday Type</label>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="Optional"
                              name="holidayType"
                              value="Optional"
                              placeholder="Optional"
                              checked={checked}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="Optional">Optional</label>
                          </div>
                        </div>
                      </Inputs>
                    </>
                  ) : null}

                  <Btns>
                    <button type="submit">
                      {formId ? "UPDATE" : " CREATE"}
                    </button>
                    <button onClick={handleClose}>CANCEL</button>
                  </Btns>
                </form>
              </Form>
            </Box>
          </Container>
        </>
      ) : null}
    </>
  );
};
// #2F80ED


const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 10px 70px;
  border: 2px solid;
  border-color: ${(props) => (props.showField ? "#999" : "#2F80ED")};
  background-color: #fff;
  color: ${(props) => (props.showField ? "#999" : "#2F80ED")};
  border-radius: 3px;
  cursor: pointer;
  font-weight: 530;
  letter-spacing: 1.5px;
  font-weight: 600;
`;

const Container = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
`;

const Box = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  height: 95vh;
  overflow-y: auto;
  margin-left: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-clip: content-box;
    height: 50vh;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 20px;
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 5px;
  /* background-color: red; */
`;

const Form = styled.div`
  padding: 20px;
  padding-top: 0;
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      font-weight: 500;
    }
    input {
      &::placeholder {
        color: #000;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

const Inputs = styled.div`
  .document-images {
    width: 48%;
  }
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  div {
    flex-basis: 100%;
  }
  input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin-bottom: 10px;
  }
  select {
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
      .checkbox {
    display: flex;
    align-items: center;
    gap: 8px;            
  }
  
  .checkbox input[type="checkbox"] {
    width: auto; /* Ensures checkbox doesn't stretch */
    margin: 0;   /* Removes any default margin */
  }
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0;
  button {
    background: #1976d2;
    padding: 10px 70px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 530;
    letter-spacing: 1.5px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    &:last-child {
      background: #fff;
      color: #000;
      box-shadow: none;
      border: 1px solid #000;
    }
  }
`;

export default Addholidays;
