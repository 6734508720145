import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { eachDayOfInterval, startOfMonth, endOfMonth, format } from "date-fns";
import db from "../firebase";

export const getSiteLocation = async () => {
  const locationRef = query(collection(db, "SiteData"));
  const siteLocationArray = [];
  try {
    const querySnapshot = await getDocs(locationRef);
    querySnapshot.forEach((doc) => {
      siteLocationArray.push(doc?.data()?.name);
    });
  } catch (error) {
    console.log(error);
  }

  return siteLocationArray;
};

export const getFormData = async (
  startDate,
  endDate,
  companyName,
  selectedVendor
) => {
  const finalEndDate = new Date(endDate)
  finalEndDate.setDate(finalEndDate.getDate() + 1);

  const data = [];
  const formRef = collection(db, "FormData");
  let dataRef = query(
    formRef,
    where("deliveryTime", ">=", startDate),
    where("deliveryTime", "<=", finalEndDate)
  );

  if (companyName) {
    dataRef = query(dataRef, where("unloadedAt", "==", companyName));
  }

  if (selectedVendor) {
    dataRef = query(dataRef, where("vendorName", "==", selectedVendor));
  }

  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const getSupervisors = async () => {
  const supervisorRef = query(collection(db, "LoginPhones"));
  const supervisors = [];
  try {
    const querySnapshot = await getDocs(supervisorRef);
    querySnapshot.forEach((doc) => {
      const {
        name,
        phoneNumber: phone,
        SiteLocation: siteLocation,
      } = doc?.data();
      supervisors.push({ name, phone, siteLocation, id: doc.id });
    });
  } catch (error) {
    console.log(error);
  }

  return supervisors;
};

export const getVendors = async () => {
  const Ref = query(collection(db, "VendorData"), orderBy("name"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc?.data(), id: doc.id });
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getMaterials = async () => {
  const Ref = query(collection(db, "MaterialData"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getSites = async () => {
  const Ref = query(collection(db, "SiteData"));
  const data = [];
  try {
    const querySnapshot = await getDocs(Ref);
    querySnapshot.forEach((doc) => {
      data.push(doc?.data());
    });
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getHolidayData = async (month, year) => {
  try {
    if (!month || !year) {
      throw new Error("Month and year are required");
    }

    const startDate = new Date(`${year}-${month.toString().padStart(2, "0")}-01`)
      .toISOString()
      .split("T")[0];
    const endDate = endOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`))
      .toISOString()
      .split("T")[0];

    const holidaysQuery = query(
      collection(db, "Holidays"),
      where("date", ">=", startDate),
      where("date", "<=", endDate)
    );

    const querySnapshot = await getDocs(holidaysQuery);
    const holidays = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { hstatus: true, hdata: holidays };
  } catch (error) {
    console.error("Error fetching holiday data:", error);
    return { hstatus: false, error };
  }
};

const emptyAttendanceDocData = {
  checkInTime: null,
  checkOutTime: null,
  status: "Absent",
};

export const getUserAttendanceByMonth = async (phoneNumber, month, year) => {
  if (!phoneNumber || !month || !year) {
    return { status: false, error: "Phone number, month, and year are required" };
  }

  try {
    // Generate all days for the specified month and year
    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`)),
      end: endOfMonth(new Date(`${year}-${month.toString().padStart(2, "0")}-01`)),
    }).map((date) => format(date, "yyyy-MM-dd"));

    // Firestore query
    const attendanceQuery = query(
      collection(db, "UserAttendance"),
      where("phoneNumber", "==", phoneNumber),
      where("date", ">=", daysInMonth[0]), // Start date
      where("date", "<=", daysInMonth[daysInMonth.length - 1]) // End date
    );

    const querySnapshot = await getDocs(attendanceQuery);

    // Extract Firestore data
    const firebaseData = {};
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      firebaseData[docData.date] = {
        id: doc.id,
        ...docData,
        ...(docData?.checkInTime && {
          checkInTime: format(
            new Date(docData.checkInTime.seconds * 1000),
            "h:mm a"
          ),
        }),
        ...(docData?.checkOutTime && {
          checkOutTime: format(
            new Date(docData.checkOutTime.seconds * 1000),
            "h:mm a"
          ),
        }),
      };
    });

    // Align Firestore data with the full month
    const alignedData = daysInMonth.map((date) => ({
      ...(firebaseData[date] || emptyAttendanceDocData),
      date,
      id: firebaseData[date]?.id || date,
      phoneNumber,
    }));

    return { status: true, data: alignedData };
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return { status: false, error };
  }
};

export const getAllUsers = async () => {
  try {
    const usersCollection = collection(db, "LoginPhones"); 
    const querySnapshot = await getDocs(usersCollection);

    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { status: true, data: users };
  } catch (error) {
    console.error("Error fetching users:", error);
    return { status: false, error: error.message };
  }
};

export const getUserByPhoneNumber = async (phoneNumber) => {
  let mobileNumber = "+"+phoneNumber.toString();
  mobileNumber = mobileNumber.replace(" ", "");
  try {
    const userQuery = query(
      collection(db, "LoginPhones"), 
      where("phoneNumber", "==", mobileNumber)
    );
    const querySnapshot = await getDocs(userQuery);

    const userData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { status: true, data: userData };
  } catch (error) {
    console.error("Error fetching user by phone number:", error);
    return { status: false, error: error.message };
  }
};

export const getUserForPhoneNumber = async (phoneNumber) => {
  try {
    const userQuery = query(
      collection(db, "LoginPhones"), 
      where("phoneNumber", "==", phoneNumber)
    );
    const querySnapshot = await getDocs(userQuery);

    const userData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return { status: true, data: userData };
  } catch (error) {
    console.error("Error fetching user by phone number:", error);
    return { status: false, error: error.message };
  }
};